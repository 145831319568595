import { lighten } from 'polished';
import Calendar from 'react-calendar';
import styled, { css } from 'styled-components';

import { TextInputProps } from '@/common/components/form/date-picker/index';

const Label = styled.label`
  ${({ theme }) => css`
    display: block;
    color: ${theme.colors.black};
    font: ${theme.font.bold} ${theme.font.sizes.xsmall} ${theme.font.family};
    margin-bottom: ${theme.spacings.xxsmall};
  `}
`;

const Root = styled.div<{ required?: boolean; noSpace: boolean }>`
  ${({ theme, required, noSpace }) => css`
    position: relative;

    ${!noSpace && `margin-bottom: ${theme.spacings.small}`}};
    ${
      required &&
      css`
        ${Label} {
          &::after {
            content: '∗';
            color: ${theme.colors.danger};
            margin-left: 5px;
          }
        }
      `
    }

    .react-calendar {
      position: absolute;
      background: white;
      border: 1px solid #a0a096;
      padding: 8px;
      margin-top: 8px;
      border-radius: ${theme.border.radius};
      min-width: 320px;
      z-index: ${theme.layers.alwaysOnTop};
    }

    .react-calendar button {
      margin: 0;
      border: 0;
      outline: none;
    }

    .react-calendar button:enabled:hover {
      cursor: pointer;
    }

    .react-calendar__navigation {
      display: flex;
      height: 44px;
      margin-bottom: 1em;
    }

    .react-calendar__navigation button {
      min-width: 32px;
      background: none;
    }

    .react-calendar__navigation button:disabled {
      background-color: #f0f0f0;
    }

    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: #e6e6e6;
    }

    .react-calendar__navigation__label__labelText,
    .react-calendar__navigation__label__labelText--from {
      text-transform: capitalize;
      font-weight: bold;
      font-size: ${theme.font.sizes.small};
    }

    .react-calendar__month-view__weekdays {
      text-align: center;
      text-transform: uppercase;
      font: inherit;
      font-size: 0.75em;
      font-weight: bold;
    }

    .react-calendar__month-view__weekdays__weekday {
      padding: 0.5em;
    }

    .react-calendar__month-view__weekNumbers .react-calendar__tile {
      display: flex;
      align-items: center;
      justify-content: center;
      font: inherit;
      font-size: 0.75em;
      font-weight: bold;
    }

    .react-calendar__month-view__days__day--weekend {
      color: #d10000;
    }

    .react-calendar__month-view__days__day--neighboringMonth,
    .react-calendar__decade-view__years__year--neighboringDecade,
    .react-calendar__century-view__decades__decade--neighboringCentury {
      color: #757575;
    }

    .react-calendar__year-view .react-calendar__tile,
    .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__century-view .react-calendar__tile {
      padding: 2em 0.5em;
    }

    .react-calendar__tile {
      max-width: 100%;
      padding: 10px 6.6667px;
      background: none;
      text-align: center;
      line-height: 16px;
      font: inherit;
      font-size: 0.833em;
      border-radius: 50%;
    }

    .react-calendar__tile:disabled {
      background-color: #f0f0f0;
      color: #ababab;
      border-radius: 50%;
    }

    .react-calendar__month-view__days__day--neighboringMonth:disabled,
    .react-calendar__decade-view__years__year--neighboringDecade:disabled,
    .react-calendar__century-view__decades__decade--neighboringCentury:disabled {
      color: #cdcdcd;
    }

    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: #e6e6e6;
    }

    .react-calendar__tile--now {
      color: ${theme.colors.white};
      background: ${lighten(0.1, theme.colors.primary)};
      border-radius: 50%;
    }

    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
      background: ${theme.colors.primary};
    }

    .react-calendar__tile--hasActive {
      background: #76baff;
    }

    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
      background: #a9d4ff;
    }

    .react-calendar__tile--active {
      background: #006edc;
      color: white;
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      background: #1087ff;
    }

    .react-calendar--selectRange .react-calendar__tile--hover {
      background-color: #e6e6e6;
    }
  `}
`;

const TextInput = styled.input<TextInputProps>`
  ${({ theme, valid }) => css`
    background: ${theme.colors.lightGray};
    border-radius: ${theme.border.radius};
    padding: ${theme.spacings.xsmall};
    outline: 1px solid ${theme.colors.borders};
    width: 100%;
    min-width: 175px;
    border: 0;
    color: ${theme.colors.black};
    font: ${theme.font.sizes.small} ${theme.font.family};

    &:focus {
      outline: 1px solid ${theme.colors.primary};
    }

    &[type='date'] {
      position: relative;
    }

    ${!valid &&
    css`
      outline: 1px solid ${theme.colors.danger};
    `}
  `}
`;

const ContentIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 38px;

  svg {
    margin-left: 16px;
  }

  * {
    cursor: pointer;
  }
`;

const ErrorMessage = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.danger};
    font-size: ${theme.font.sizes.xsmall};
  `}
`;

const Overlay = styled.div<{ visible: boolean }>`
  ${({ theme, visible }) => css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${theme.layers.overlay};

    ${!visible &&
    css`
      display: none;
      opacity: 0;
      pointer-events: none;
    `};
  `}
`;

const CalendarDatePicker = styled(Calendar)<{ visible: boolean }>`
  ${({ visible }) => css`
    ${!visible &&
    css`
      display: none;
    `};
  `}}
`;

export {
  CalendarDatePicker,
  ContentIcon,
  ErrorMessage,
  Label,
  Overlay,
  Root,
  TextInput,
};
