import IMask from 'imask';

const timeMaskProps = {
  mask: 'HH{:}`MM',
  blocks: {
    HH: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 24,
      maxLength: 2,
    },
    MM: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
    },
  },
};

export { timeMaskProps };
