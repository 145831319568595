import React, { InputHTMLAttributes, useState } from 'react';
import { FieldMetaProps } from 'formik/dist/types';
import { FiCalendar } from 'react-icons/fi';
import { IoIosClose } from 'react-icons/io';
import {
  CalendarDatePicker,
  ContentIcon,
  ErrorMessage,
  Label,
  Overlay,
  Root,
  TextInput,
} from '@/common/components/form/date-picker/styles';
import { format } from 'date-fns';

export type DatePickerProps = {
  label?: string;
  labelFor?: string;
  name: string;
  inputRef?: any;
  meta?: FieldMetaProps<any>;
  noSpace?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;
export type TextInputProps = {
  valid: boolean;
} & InputHTMLAttributes<HTMLInputElement>;
const DatePicker = ({
  label,
  labelFor = '',
  required,
  meta,
  inputRef,
  onChange,
  value,
  className,
  noSpace = false,
  ...props
}: DatePickerProps) => {
  const [visible, setVisibility] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  };
  const handleVisibility = () => {
    setVisibility(!visible);
  };
  return (
    <Root required={required} noSpace={noSpace} className={className}>
      {!!label && <Label htmlFor={labelFor}>{label}</Label>}
      <TextInput
        valid={!(meta?.touched && meta?.error)}
        ref={inputRef}
        type={'text'}
        {...props}
        value={value}
        onChange={handleChange}
      />
      <ContentIcon>
        <IoIosClose
          onClick={() => {
            const customEvent: any = {
              target: {
                name: props.name,
                value: '',
              },
            };
            handleChange(customEvent);
          }}
        />
        <FiCalendar onClick={handleVisibility} />
      </ContentIcon>
      <CalendarDatePicker
        locale={'pt-BR'}
        visible={visible}
        allowPartialRange={false}
        onChange={v => {
          const customEvent: any = {
            target: {
              name: props.name,
              value: format(v as Date, 'dd/MM/yyyy'),
            },
          };
          handleChange(customEvent);
          handleVisibility();
        }}
      />
      <Overlay visible={visible} onClick={handleVisibility} />
      {meta?.touched && meta?.error && (
        <ErrorMessage>{meta.error}</ErrorMessage>
      )}
    </Root>
  );
};
export { DatePicker };
