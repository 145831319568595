import React from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export type CardProps = {
  padded?: boolean;
} & React.HtmlHTMLAttributes<HTMLDivElement>;

const Card = styled.div<CardProps>`
  ${({ theme, padded }) => css`
    border-radius: ${theme.border.radius};
    background: ${theme.colors.white};
    box-shadow: 0 0 20px ${transparentize(0.96, theme.colors.black)};
    padding-top: ${theme.spacings.small};
    padding-bottom: ${theme.spacings.small};
    ${padded &&
    css`
      padding: ${theme.spacings.medium};
    `}
    margin-bottom: ${theme.spacings.small};
  `}
`;

const CardContent = styled.div`
  ${({ theme }) => css`
    padding-right: ${theme.spacings.small};
    padding-left: ${theme.spacings.small};
    &:not(:last-child) {
      margin-bottom: ${theme.spacings.small};
    }
  `}
`;

const CardFooter = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: ${theme.spacings.small};
    padding-left: ${theme.spacings.small};
    &:not(:last-child) {
      margin-bottom: ${theme.spacings.small};
    }

    & a:last-child {
      margin-left: auto;
    }
  `}
`;

export { Card, CardContent, CardFooter };
