import React, { useEffect, useState } from 'react';

import { IMaskMixin } from 'react-imask';

import { DatePicker } from '@/common/components/form/date-picker';
import { Input, InputProps } from '@/common/components/form/input';
import { cnjMaskProps } from '@/common/components/form/masked-input/mask/cnj-mask-props';
import { cnpjMaskProps } from '@/common/components/form/masked-input/mask/cnpj-mask-props';
import { cpfCnpjMaskProps } from '@/common/components/form/masked-input/mask/cpf-cnpj-mask-props';
import { cpfMaskProps } from '@/common/components/form/masked-input/mask/cpf-mask-props';
import { creditCardMaskProps } from '@/common/components/form/masked-input/mask/credit-card-mask-props';
import { CurrencyMaskProps } from '@/common/components/form/masked-input/mask/currency-mask-props';
import { dateMaskProps } from '@/common/components/form/masked-input/mask/date-mask-props';
import { expiryCardMaskProps } from '@/common/components/form/masked-input/mask/expiry-card-mask-props';
import { phoneMaskProps } from '@/common/components/form/masked-input/mask/phone-mask-props';
import { postalCodeMaskProps } from '@/common/components/form/masked-input/mask/postal-code-mask-props';
import { timeMaskProps } from '@/common/components/form/masked-input/mask/time-mask-props';
import { dateToBrFormat } from '@/common/helpers/date';
import { format, isValid, parse } from 'date-fns';

const MaskedInputWrapper = IMaskMixin(({ inputRef, ...props }: any) => (
  <Input {...props} inputRef={inputRef} />
));

const MaskedDatePicker = IMaskMixin(({ inputRef, ...props }: any) => (
  <DatePicker {...props} inputRef={inputRef} />
));

export type MasksTypes =
  | 'phone'
  | 'cpf'
  | 'cnpj'
  | 'cpfCnpj'
  | 'cnj'
  | 'money'
  | 'postalCode'
  | 'creditCard'
  | 'expiryCard'
  | 'time'
  | 'date';

const masksByType = {
  phone: phoneMaskProps,
  cpf: cpfMaskProps,
  cnpj: cnpjMaskProps,
  cpfCnpj: cpfCnpjMaskProps,
  cnj: cnjMaskProps,
  postalCode: postalCodeMaskProps,
  creditCard: creditCardMaskProps,
  expiryCard: expiryCardMaskProps,
  time: timeMaskProps,
  date: dateMaskProps,
};

type Props = InputProps & {
  mask: MasksTypes;
  value: string;
};

export const MaskedInput = React.memo(({ mask, onChange, ...props }: Props) => {
  const [tempValue, setTempValue] = useState(
    props.value
      ? dateToBrFormat({
          date: new Date(props.value),
          ignoreTimezone: true,
        })
      : ''
  );

  useEffect(() => {
    setTempValue(
      props.value
        ? dateToBrFormat({
            date: new Date(props.value),
            ignoreTimezone: true,
          })
        : ''
    );
  }, [props.value]);

  if (mask === 'money') {
    return <CurrencyMaskProps {...props} onChange={onChange} />;
  }

  if (mask === 'date') {
    return (
      <MaskedDatePicker
        {...props}
        {...(masksByType['date'] as any)}
        value={tempValue}
        onAccept={(value: any, masking: any) => {
          setTempValue(masking.value);
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const eventValue = e.target!.value;
          if (!eventValue) {
            setTempValue('');
            if (onChange) {
              onChange({
                target: {
                  name: props!.name,
                  value: '',
                },
              } as any);
            }
            return;
          } else if (eventValue.length === 10) {
            const date = parse(eventValue, 'dd/MM/yyyy', new Date());
            let customEvent: any;
            if (isValid(date)) {
              customEvent = {
                target: {
                  name: props!.name,
                  value: format(date, 'yyyy-MM-dd'),
                },
              };
            } else {
              customEvent = {
                target: {
                  name: props!.name,
                  value: '',
                },
              };
              setTempValue('');
            }
            if (onChange) onChange(customEvent);
          }
        }}
      />
    );
  }
  const maskProps = masksByType[mask] as any;

  return (
    <MaskedInputWrapper
      {...props}
      {...maskProps}
      onAccept={(value: any, masking: any) => {
        if (onChange) {
          const customEvent: any = {
            target: {
              name: props!.name,
              value: mask === 'cnj' ? value : masking.unmaskedValue,
            },
          };
          onChange(customEvent);
        }
      }}
    />
  );
});

MaskedInput.displayName = 'MaskedInput';
