import React from 'react';
import NumberFormat from 'react-number-format';
import { Input } from '@/common/components/form/input';

const CurrencyMaskProps = React.forwardRef(function NumberFormatCustom(
  props: any,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      customInput={Input}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={'.'}
      decimalSeparator={','}
      decimalScale={2}
      allowLeadingZeros={true}
      fixedDecimalScale={true}
      isNumericString
      prefix=""
    />
  );
});

export { CurrencyMaskProps };
