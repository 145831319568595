import IMask from 'imask';

const expiryCardMaskProps = {
  mask: '00/00',
  pattern: 'm/`Y',
  blocks: {
    m: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      maxLength: 2,
    },
    Y: {
      mask: IMask.MaskedRange,
      from: 20,
      to: 99,
    },
  },
};

export { expiryCardMaskProps };
