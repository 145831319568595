import IMask from 'imask';
import { format, parse } from 'date-fns';

const formatBR = 'dd/MM/yyyy';

const dateMaskProps = {
  mask: Date,
  pattern: 'DD/MM/YYYY',
  lazy: true,
  min: new Date(1970, 0, 1),
  max: new Date(2090, 0, 1),

  format: (date: any) => {
    return format(date, formatBR);
  },
  parse: (str: any) => {
    console.log(str);
    return parse(str, formatBR, new Date());
  },

  blocks: {
    YYYY: {
      mask: IMask.MaskedRange,
      from: 1970,
      to: 2090,
      maxLength: 4,
    },
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      maxLength: 2,
    },
    DD: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
      maxLength: 2,
    },
  },
};

export { dateMaskProps };
